<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li
          v-if="role === 'Admin' || role === 'Branch Admin'"
          class="nav-item"
          v-on:click="collapseAll"
        >
          <router-link class="nav-link" to="/dashboard">
            <span class="menu-title">แดชบอร์ด</span>
            <i class="mdi mdi-view-dashboard menu-icon"></i>
          </router-link>
        </li>
        <li
          v-if="role === 'Admin' || role === 'Branch Admin'"
          class="nav-item"
          v-on:click="collapseAll"
        >
          <router-link class="nav-link" to="/users">
            <span class="menu-title">จัดการผู้ใช้งาน</span>
            <i class="mdi mdi-account-circle menu-icon"></i>
          </router-link>
        </li>
        <li
          v-if="
            role === 'Account manager' ||
            (role === 'Checker' && branch === '1') ||
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'weightlist-page-dropdown'">
            <span class="menu-title">สต็อคกลาง</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-package-variant-closed menu-icon"></i>
          </span>
          <b-collapse
            accordion="sidebar-accordion"
            id="weightlist-page-dropdown"
          >
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/weight-list"
                  >ม้วนเหล็ก</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link text-overflow" to="/miscellaneous"
                  >สินค้าอื่นๆ</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            (role === 'Checker' && branch !== '1') ||
            role === 'Branch Admin assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'branch-stock-page-dropdown'">
            <span class="menu-title">คลังสาขา</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-package-variant-closed menu-icon"></i>
          </span>
          <b-collapse
            accordion="sidebar-accordion"
            id="branch-stock-page-dropdown"
          >
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/branch-stock"
                  >ม้วนเหล็ก</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/branch-stock/miscellaneous"
                  >สินค้าอื่นๆ</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant'
          "
          class="nav-item"
          v-on:click="collapseAll"
        >
          <router-link class="nav-link" to="/request">
            <span class="menu-title">ใบคำขอสินค้า</span>
            <i class="mdi mdi-format-quote-close menu-icon"></i>
          </router-link>
        </li>
        <li
          v-if="
            role === 'Account manager' ||
            role === 'Checker' ||
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant'
          "
          class="nav-item"
          v-on:click="collapseAll"
        >
          <router-link class="nav-link" to="/order-centric">
            <span class="menu-title">ใบส่งม้วน</span>
            <i class="mdi mdi-truck-fast menu-icon"></i>
          </router-link>
        </li>

        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Account manager'
          "
          class="nav-item"
          v-on:click="collapseAll"
        >
          <router-link class="nav-link" to="/invoice">
            <span class="menu-title">ใบวางบิล / ใบแจ้งหนี้</span>
            <i class="mdi mdi-cash-usd menu-icon"></i>
          </router-link>
        </li>

        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant' ||
            role === 'Account executive' ||
            role === 'Customer assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'quotation-page-dropdown'">
            <span class="menu-title">ใบเสนอราคา</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-file-document menu-icon"></i>
          </span>
          <b-collapse
            accordion="sidebar-accordion"
            id="quotation-page-dropdown"
          >
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/quotation"
                  >ทั้งหมด</router-link
                >
              </li>
              <li v-if="role === 'Branch Admin'" class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/quotation/accounts"
                  >บัญชีในการชำระ</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant' ||
            role === 'Customer assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'sell-page-dropdown'">
            <span class="menu-title">เอกสารขาย</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-currency-usd menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="sell-page-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/deposit"
                  >ใบมัดจํา</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/billing"
                  >ใบรับคำสั่งซื้อ</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/delivery"
                  >บิลขาย (ใบส่งสินค้า/ใบแจ้งหนี้)</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/payment"
                  >รับชำระหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/tax-invoice"
                  >ใบเสร็จรับเงิน/ใบกำกับภาษี</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li
          v-if="
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant' ||
            role === 'Customer assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'production-dropdown'">
            <span class="menu-title">การผลิต</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-memory menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="production-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/production"
                  >ใบผลิต</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <!-- <li v-if="role === 'Admin' || role === 'Branch Admin'" class="nav-item">
          <span class="nav-link" v-b-toggle="'credit-page-dropdown'">
            <span class="menu-title">การเงินการบัญชี</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-currency-usd menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="credit-page-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/deposit"
                  >ใบมัดจํา</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/balance"
                  >ยอดหนี้คงเหลือ</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/payment"
                  >รับชำระหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/receipt"
                  >ใบเสร็จรับเงิน</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/tax-invoice"
                  >ใบกำกับภาษี</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li> -->
        <li v-if="role === 'Admin' || role === 'Branch Admin'" class="nav-item">
          <span class="nav-link" v-b-toggle="'reports'">
            <span class="menu-title">รายงาน</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-chart-pie menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="reports">
            <ul class="nav flex-column sub-menu">
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin' && branch === '1')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/report/balance/"
                  v-b-tooltip.html.right
                  title="รายงานสินค้าคงเหลือ (สต็อคกลาง)"
                  >รายงานสินค้าคงเหลือ (สต็อคกลาง)</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/inbound/"
                  v-b-tooltip.html.right
                  title="รายงานใบสั่งสินค้าขาเข้า พร้อมสถานะล่าสุด"
                  >รายงานใบสั่งสินค้าขาเข้า พร้อมสถานะล่าสุด</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/outbound/"
                  v-b-tooltip.html.right
                  title="รายงานใบสั่งสินค้าขาออก พร้อมสถานะล่าสุด"
                  >รายงานใบสั่งสินค้าขาออก พร้อมสถานะล่าสุด</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/manufacture/"
                  v-b-tooltip.html.right
                  title="รายงานใบสั่งผลิต พร้อมสถานะล่าสุด"
                  >รายงานใบสั่งผลิต พร้อมสถานะล่าสุด</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/ar-aging/"
                  v-b-tooltip.html.right
                  title="รายงานลูกหนี้คงค้างและอายุหนี้"
                  >รายงานลูกหนี้คงค้างและอายุหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/movement/"
                  v-b-tooltip.html.right
                  title="รายงานความเคลื่อนไหวสินค้า"
                  >รายงานความเคลื่อนไหวสินค้า</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/ar-sale/"
                  v-b-tooltip.html.right
                  title="รายงานลูกหนี้คงค้างแยกตามพนักงานขายและกลุ่มลูกหนี้"
                  >รายงานลูกหนี้คงค้างแยกตามพนักงานขายและกลุ่มลูกหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/ar-payment/"
                  v-b-tooltip.html.right
                  title="รายงานการกำหนดชำระหนี้"
                  >รายงานการกำหนดชำระหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/sale/"
                  v-b-tooltip.html.right
                  title="รายงานยอดขายรวมและแยกกลุ่ม"
                  >รายงานยอดขายรวมและแยกกลุ่ม</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/spending/"
                  v-b-tooltip.html.right
                  title="รายงานค่าใช้จ่าย"
                  >รายงานค่าใช้จ่าย</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/revenue/"
                  v-b-tooltip.html.right
                  title="รายงานรายรับรายจ่ายประจำวัน"
                  >รายงานรายรับรายจ่ายประจำวัน</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/reservation/"
                  v-b-tooltip.html.right
                  title="รายงานการจองสินค้า"
                  >รายงานการจองสินค้า</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/repay/"
                  v-b-tooltip.html.right
                  title="รายงานรับชำระหนี้"
                  >รายงานรับชำระหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/credit-note/"
                  v-b-tooltip.html.right
                  title="รายงานคืนสินค้าและลดหนี้"
                  >รายงานคืนสินค้าและลดหนี้</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/withholding-tax/"
                  v-b-tooltip.html.right
                  title="รายงานแสดงยอดภาษีหัก ณ ที่จ่าย"
                  >รายงานแสดงยอดภาษีหัก ณ ที่จ่าย</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/report/profitability/"
                  v-b-tooltip.html.right
                  title="รายงานวิเคราะห์ต้นทุนกำไรขาดทุน"
                  >รายงานวิเคราะห์ต้นทุนกำไรขาดทุน</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li
          v-if="
            role === 'Account manager' ||
            role === 'Admin' ||
            role === 'Branch Admin' ||
            role === 'Branch Admin assistant' ||
            role === 'Account manager' ||
            role === 'Assistant account manager' ||
            role === 'Sales manager' ||
            role === 'Assistant sales manager' ||
            role === 'Account executive' ||
            role === 'Customer assistant'
          "
          class="nav-item"
        >
          <span class="nav-link" v-b-toggle="'customers'">
            <span class="menu-title">ข้อมูลลูกค้า</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-account-multiple menu-icon"></i>
          </span>
          <!-- <router-link class="nav-link" to="/masters/customer/">
            <span class="menu-title">ข้อมูลลูกค้า</span>
            <i class="mdi mdi-account-multiple menu-icon"></i>
          </router-link> -->
          <b-collapse accordion="sidebar-accordion" id="customers">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/masters/customer/">
                  ทั้งหมด
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="role === 'Admin' || role === 'Branch Admin'"
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/customer/types"
                  >ประเภทลูกค้า</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li v-if="role === 'Admin' || role === 'Branch Admin'" class="nav-item">
          <span class="nav-link" v-b-toggle="'masters'">
            <span class="menu-title">ข้อมูลหลัก</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-archive menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="masters">
            <ul class="nav flex-column sub-menu">
              <strong v-if="role === 'Admin'"><p>ข้อมูลทั่วไป</p></strong>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin' && branch === '1')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/branch/"
                  >ข้อมูลสาขา</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="role === 'Account manager' || role === 'Admin'"
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/supplier/"
                  >ข้อมูลผู้ขาย</router-link
                >
              </li>
              <li class="nav-item" v-if="role === 'Admin'">
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/location/"
                  v-b-tooltip.html.right
                  title="ข้อมูลเครื่องจักรและที่เก็บสินค้า"
                  >ข้อมูลเครื่องจักรและที่เก็บสินค้า</router-link
                >
              </li>
              <hr class="border-gray-400 w-100" v-if="role === 'Admin'">
              <strong><p>งานขาย</p></strong>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/product-types/"
                  >ชนิดสินค้า</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/skus/"
                  >SKU</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/colors/"
                  >สี</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/sizes/"
                  >ความหนา</router-link
                >
              </li>
              <li
                class="nav-item"
                v-if="
                  role === 'Admin' ||
                  (role === 'Branch Admin')
                "
              >
                <router-link
                  class="nav-link text-overflow"
                  to="/masters/brands/"
                  >ยี่ห้อ</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li v-if="role === 'Admin'" class="nav-item">
          <span class="nav-link" v-b-toggle="'settings'">
            <span class="menu-title">ตั้งค่าระบบ</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-settings menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="settings">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/settings/company/"
                  >ข้อมูลบริษัท</router-link
                >
              </li>
              <!-- <li class="nav-item">
                <router-link
                  class="nav-link text-overflow"
                  to="/check/image"
                  >ทดสอบ</router-link
                >
              </li> -->
            </ul>
          </b-collapse>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      role: "",
      branch: "",
      user: {
        image: "",
        name: "sangthai",
        role: "admin",
      },
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    async getRole() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      this.user.name = res.data.firstname + " " + res.data.lastname;
      this.user.role = res.data.role;
      this.role = res.data.role;
      this.branch = res.data.branch_id;
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    }),
      this.getRole();
  },
  watch: {},
};
</script>

<style scoped>
.text-overflow {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  width: calc(100%);
  display: inline-block !important;
}
</style>
