<template>
  <b-navbar
    id="template-header"
    class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    style="z-index: 900"
    toggleable="lg"
  >
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center ml-3 ml-lg-0"
    >
      <router-link
        class="align-self-end brand-logo flex navbar-brand router-link-active"
        to="/profile"
      >
        <img src="@/assets/logo_sidebar.png" alt="logo" />
        <!-- <h2 class="font-weight-bold color-sangthai">SANGTHAI</h2> -->
      </router-link>
      <router-link
        class="align-self-end navbar-brand flex brand-logo-mini"
        to="/profile"
      >
        <!-- <h3 class="font-weight-bold mb-3">S</h3> -->
        <img src="@/assets/favicon.png" alt="logo_mini" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
      <b-navbar-nav class="navbar-nav-right ml-auto">
        <b-nav-item-dropdown right class="nav-profile">
          <template slot="button-content">
            <span
              class="nav-link dropdown-toggle"
              id="profileDropdown"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="nav-profile-img">
                <!-- <img v-if="!user.image" src="@/assets/images/user-default.png" alt="image"> -->
                <!-- <img v-if="!user.image" src="@/assets/favicon.png" alt="image">
                <img v-else :src="user.image" alt="image" srcset="">
                <span class="availability-status online"></span> -->
                <div
                  class="bg-white h-fit outline-sangthai overflow-hidden rounded-pill w-fit"
                >
                  <!-- Image/Letter -->
                  <!-- <img v-if="!user.image" src="@/assets/images/user-default.png" alt="image"> -->
                  <img
                    v-if="!user.image"
                    src="@/assets/favicon.png"
                    class="object-cover"
                    alt="image"
                  />
                  <img
                    v-else
                    :src="user.image"
                    alt="image"
                    srcset=""
                    class="object-cover"
                  />
                  <span class="availability-status online"></span>
                </div>
              </div>
              <div class="nav-profile-text d-flex flex-column">
                <p
                  class="text-white text-capitalize font-weight-bold mr-2 mb-2 profile-text"
                >
                  {{ user.name }}
                </p>
                <span class="text-capitalize text-white profile-text"
                  >{{ user.role !== "-" ? user.role : "ผู้ดูแลระบบ" }} ({{
                    user.branch_name ? user.branch_name : "ไม่มีสาขา"
                  }})</span
                >
              </div>
            </span>
          </template>
          <b-dropdown-item class="preview-item" @click="next('profile')">
            <i class="mdi mdi-face mr-2 text-primary"></i> ข้อมูลส่วนตัว
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" @click="logout()">
            <i class="mdi mdi-logout mr-2 text-primary"></i> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {
      user: {
        image: "",
        name: "Loading...",
        role: "Loading..",
        branch_name: "...",
      },
    };
  },
  methods: {
    async getRole() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      this.user.name = res.data.firstname + " " + res.data.lastname;
      this.user.role = this.onInitPositionSettings(res.data.role);
      this.user.branch_name = res.data.detail?.name;
    },
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    logout() {
      localStorage.Sangthai_isLoggedIn = false;
      localStorage.Sangthai_access_token = null;
      localStorage.Sangthai_expires_in = null;
      localStorage.Sangthai_refresh_token = null;

      this.$router
        .push({
          path: "/",
        })
        .catch((error) => {
          console.info(error.message);
        });
    },
    next(target) {
      this.$router
        .push({
          name: target,
        })
        .catch((error) => {
          console.info(error.message);
        });
    },
  },
  mounted() {
    this.getRole();
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .profile-text {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
