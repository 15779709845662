import Vue from 'vue'
import moment from 'moment'
import 'vue2-datepicker/locale/th';
const _ = require('lodash')
import ThaiBahtText from 'thai-baht-text'

moment.defineLocale('th', {
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ],
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.'
  ],
  weekdays: [
    'อาทิตย์',
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์'
  ],
  weekdaysShort: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']
})

Vue.mixin({
  filters: {
    number(value) {
      if (!value) { return 0 }
      const number = parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return number
    },
    getDateShortFormatted(data) {
      const date = new Date(data);
      const result = date.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      return result
    },
    numberFormat(value) {
      if (!value) { return 0 }
      const number = parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return number
    },
    getFileExtension(data) {
      let extension = ''
      if (data) {
        const file_ext = data.split('.')
        extension = file_ext[file_ext.length - 1]
      }
      return extension
    }
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      lg: {
        days: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
        months: [
          'ม.ค.',
          'ก.พ.',
          'มี.ค.',
          'เม.ย.',
          'พ.ค.',
          'มิ.ย.',
          'ก.ค.',
          'ส.ค.',
          'ก.ย.',
          'ต.ค.',
          'พ.ย.',
          'ธ.ค.'
        ]
      },
      momentFormat: {
        // [optional] Date to String
        stringify: (date) => {
          return date ? moment(date).format('ll') : ''
        },
        // [optional]  String to Date
        parse: (value) => {
          return value ? moment(value, 'll').toDate() : null
        }
      },
      monthFormat: {
        // [optional] Date to String
        stringify: (date) => {
          return date ? moment(date).format('YYYY MMM') : ''
        },
        // [optional]  String to Date
        parse: (value) => {
          return value ? moment(value, 'YYYY MMM').toDate() : null
        }
      },
      departments: [
        { text: 'ผู้จัดการสาขา', value: 'manager' },
        { text: 'ฝ่ายที่ปรึกษางานขาย', value: 'consult' },
        { text: 'ฝ่ายธุรการ/บัญชี', value: 'account' },
        { text: 'ฝ่ายเทคนิค/ช่างซ่อม', value: 'technique' },
        { text: 'ฝ่ายจัดส่ง และตรวจสอบสินค้า', value: 'delivery' },
        { text: 'ฝ่ายผลิตแผ่นหลังคา', value: 'roofsheet' },
        { text: 'ฝ่ายผลิตครอบ', value: 'cover' }
      ],
      genders: [
        { value: "male", text: "ชาย" },
        { value: "female", text: "หญิง" },
      ],
      positions: [
        {
          label: 'ฝ่ายบริหาร',
          options: [
            { value: 'Branch Admin', text: 'ผู้จัดการสาขา' },
            { value: 'Branch Admin assistant', text: 'รองผู้จัดการสาขา' }
          ]
        },
        {
          label: 'ฝ่ายที่ปรึกษางานขาย',
          options: [
            // { value: 'Sales manager', text: 'หัวหน้าที่ปรึกษางานขาย' },
            // { value: 'Assistant sales manager', text: 'รองหัวหน้าที่ปรึกษางานขาย' },
            { value: 'Account executive', text: 'ที่ปรึกษางานขาย และประสานงานดูแลลูกค้า' }
          ]
        },
        {
          label: 'ฝ่ายธุรการ/บัญชี',
          options: [
            // { value: 'Account manager', text: 'หัวหน้าธุรการ/บัญชี' },
            // { value: 'Assistant account manager', text: 'รองหัวหน้าธุรการ/บัญชี' },
            // { value: 'Accountant', text: 'บัญชี และการเงิน' },
            { value: 'Customer assistant', text: 'ดูแลลูกค้า และสนับสนุนงานขาย' }
          ]
        },
        {
          label: 'ฝ่ายเทคนิค/ช่างซ่อม',
          options: [
            // { value: 'Technician manager', text: 'หัวหน้าฝ่ายเทคนิค/ช่างซ่อม' },
            // { value: 'Assistant technician manager', text: 'รองฝ่ายเทคนิค/ช่างซ่อม' },
            // { value: 'Technician', text: 'วัดงาน และช่างซ่อม' }
          ]
        },
        {
          label: 'ฝ่ายจัดส่ง และตรวจสอบสินค้า',
          options: [
            // { value: 'Logistic manager', text: 'หัวหน้าฝ่ายจัดส่ง และตรวจสอบสินค้า' },
            // { value: 'Assistant logistic manager', text: 'รองหัวหน้าฝ่ายจัดส่ง และตรวจสอบสินค้า' },
            { value: 'Checker', text: 'ตรวจสอบสินค้า' },
            // { value: 'Pickup driver', text: 'พนักงานขนส่งรถกระบะ' },
            // { value: 'Truck driver', text: 'พนักงานขนส่งรถหกล้อ' }
          ]
        },
        {
          label: 'ฝ่ายผลิตแผ่นหลังคา',
          options: [
            // { value: 'Roof producer manager', text: 'หน้าหน้าฝ่ายผลิตแผ่นหลังคา' },
            // { value: 'Assistant roof producer manager', text: 'รองหัวหน้าฝ่ายผลิตแผ่นหลังคา' },
            // { value: 'Roof producer', text: 'พนักงานงานผลิตแผ่นหลังคา' }
          ]
        },
        {
          label: 'ฝ่ายผลิตครอบ',
          options: [
            // { value: 'Coping producer manager', text: 'หัวหน้าฝ่ายผลิตครอบ' },
            // { value: 'Assistant coping manager', text: 'รองหัวหน้าฝ่ายผลิตครอบ' },
            // { value: 'Coping producer', text: 'พนักงานผลิตครอบ' }
          ]
        }
      ],
      depositStatus: [
        { text: 'ค้างชำระ', value: 1 },
        { text: 'ชำระแล้ว', value: 2 },
        { text: 'ยกเลิก', value: 3 },
      ],
      coilBranchStatus: [
        { text: "รับเข้า", value: "received" },
        { text: "เปิดใช้งาน", value: "opened" },
        { text: "ถูกเบิกใช้", value: "using" },
        { text: "ยกเลิก/คืนของ", value: "cancel" },
      ],
      paymentMethods: [
        { value: "cash", text: "เงินสด" },
        { value: "credit", text: "เครดิต" },
        { value: "transfer", text: "เงินโอน" }
      ],
      shippingMethod: [
        { value: "1", text: "บริษัทจัดส่ง" }
      ],
      miscellaneousTypeOptions: [
        { text: 'สกรู', value: 1 },
        { text: 'อุปกรณ์ต่างๆ', value: 2 },
        { text: 'แผ่นโปร่งแสง', value: 3 },
        { text: 'วัสดุ/วัตถุในงานผลิต', value: 4 },
      ],
      miscellaneousStatusOptions: [
        { text: 'ปกติ', value: 1 },
      ],
      billStatusOptions: [
        // { text: 'รอผลิต', value: 1 },
        // { text: 'กำลังผลิต', value: 2 },
        // { text: 'รอจัดส่ง', value: 3 },
        // { text: 'กำลังจัดส่ง', value: 4 },
        // { text: 'จัดส่งแล้ว', value: 5 },
        { value: 0, text: "รอส่งสินค้า" }, //#d9d9d9
        { value: 1, text: "จัดส่งบางส่วน" }, //#ffff00
        { value: 2, text: "จัดส่งสำเร็จแล้ว" }, //#00b0f0
        { value: 3, text: "ยกเลิก" }, //ff9900
      ],
      productionStatusOption:[
        { text: 'รอผลิต', value: 1 },
        { text: 'กำลังผลิต', value: 2 },
        { text: 'ผลิตสำเร็จ', value: 3 },
        { text: 'พักการผลิต', value: 3 },
        { text: 'ยกเลิก', value: 5 },
      ],
      vatOptions: [
        { value: 0, text: "ไม่มี Vat" },
        { value: 1, text: "แยก Vat" },
        { value: 2, text: "รวม Vat" },
      ],
      paymentOptions: [
        { value: 0, text: "เงินสด" },
        { value: 1, text: "เครดิต" },
      ],
      shippingOptions: [
        { value: 0, text: "บริษัทจัดส่งสินค้า" },
        { value: 1, text: "รับสินค้าเอง" },
      ],
      depositDocument: {
        status: [
          { value: 0, text: "รอการชำระ" },
          { value: 1, text: "ชำระแล้ว" },
          { value: 2, text: "เกินกำหนด" },
          { value: 3, text: "ยกเลิก" },
        ],
      },
      depositOptions: [
        { value: 0, text: "%" },
        { value: 1, text: "บาท" },
      ],
      cuttingOptions: [
        {
          uuid: "123456a",
          description: "Q1",
          name: "ตัดตรง",
        },
        {
          uuid: "123456b",
          description: "Q2",
          name: "ครอบข้าง 457",
        },
      ],
      thicknessOptions: [
        {
          uuid: "1",
          name: "3mm",
        },
        {
          uuid: "2",
          name: "5mm",
        },
        {
          uuid: "3",
          name: "10mm",
        },
      ],
      thicknessOptions2: [
        {
          uuid: "4",
          name: "25mm",
        },
        {
          uuid: "5",
          name: "50mm",
        },
      ],
      insulatorMaterialOptions: [
        {
          uuid: "1",
          name: "ฟอย",
        },
        {
          uuid: "2",
          name: "เหล็ก",
        },
      ],
      banks: [
        'ธนาคารแห่งประเทศไทย',
        'ธนาคารกรุงเทพ',
        'ธนาคารกรุงศรีอยุธยา',
        'ธนาคารกสิกรไทย',
        'ธนาคารเกียรตินาคิน',
        'ธนาคารซีไอเอ็มบีไทย',
        'ธนาคารทหารไทยธนชาต',
        'ธนาคารทิสโก้',
        'ธนาคารไทยพาณิชย์',
        'ธนาคารไทยเครดิตเพื่อรายย่อย',
        'ธนาคารธนชาต',
        'ธนาคารยูโอบี',
        'ธนาคารแลนด์ แอนด์ เฮาส์',
        'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
        'ธนาคารกรุงไทย',
        'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
        'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
        'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
        'ธนาคารออมสิน',
        'ธนาคารอาคารสงเคราะห์',
        'ธนาคารอิสลามแห่งประเทศไทย',
        'ธนาคารไอซีบีซี (ไทย)',
      ],
      deliveryStatusOption: [
        { value: 0, text: "รอจัดส่ง" }, //#d9d9d9
        { value: 1, text: "กำลังจัดส่ง" }, //#00b0f0
        { value: 2, text: "จัดส่งสำเร็จ" }, //#00b050
        { value: 3, text: "จัดส่งไม่สำเร็จ" }, //#808080
        { value: 4, text: "จัดส่งอีกครั้ง" }, //#833c0c
        { value: 5, text: "ยกเลิก" }, //#ff0000
      ],
      showPriceOption: [
        { value: 0, text: "แสดงราคา" },
        { value: 1, text: "ไม่แสดงราคา" }, 
      ],
      statusOption: [
        { value: 0, text: "กำลังจัดทำ" }, //#d9d9d9
        { value: 1, text: "รอตรวจสอบ" }, //#ffff00
        { value: 2, text: "ยืนยันราคาแล้ว" }, //#00b0f0
        { value: 3, text: "Revise ราคา" }, //ff9900
        { value: 4, text: "ยกเลิก" }, //#ff0000
        { value: 5, text: "ตรวจสอบไม่ผ่าน" }, //931d8b
        { value: 6, text: "ได้งาน" }, //00b050
        { value: 7, text: "เทียบราคา" }, //#808080
        { value: 8, text: "คู่เทียบ" }, //#833c0c
        { value: 9, text: "ไม่ได้งาน" }, //#ff0000
      ],
      rollStatus: [
        { value: null, text: "-- กรุณาเลือกสถานะ --" },
        { text: "รอเข้า", value: 1 },
        { text: "พร้อมสั่ง", value: 2 },
        { text: "กำลังส่ง", value: 3 },
        { text: "รับแล้ว", value: 4 },
      ],
      reviseReasonOptions: [
        { text: "ทำราคาผิด", value: 1 },
        { text: "ลูกค้าขอต่อรอง", value: 2 },
      ],
      brandOptions:[{ uuid: null, name: "-- กรุณาเลือกยี่ห้อ --" }],
      sizeOptions:[{ uuid: '', name: "-- กรุณาระบุขนาด --" }],
      colorOptions:[{ uuid: null, name: "-- กรุณาระบุสี --" }],
      // brandOptions: [
      //   { value: null, text: "-- กรุณาเลือกยี่ห้อ --" },
        // { value: 'HB', text: 'HB' },
        // { value: 'JL', text: 'JL' },
        // { value: 'JJ', text: 'JJ' },
        // { value: 'DB', text: 'DB' },
        // { value: 'BHP', text: 'BHP' },
        // { value: 'NN', text: 'NN' },
        // { value: 'SM', text: 'SM' },
        // { value: 'JIS', text: 'JIS' },
        // { value: 'HY', text: 'HY' },
        // { value: 'YT', text: 'YT' },
        // { value: 'GI', text: 'GI' },
        // { value: 'VHC', text: 'VHC' },
        // { value: 'TST', text: 'TST' },
        // { value: 'TTS', text: 'TTS' },
        // { value: 'CNB', text: 'CNB' },
        // { value: 'EM-PRO', text: 'EM-PRO' },
        // { value: 'EM-STAR', text: 'EM-STAR' },
        // { value: 'EM-ONE', text: 'EM-ONE' },
        // { value: 'FY', text: 'FY' },
        // { value: 'TD', text: 'TD' },
        // { value: 'HS', text: 'HS' },
        // { value: 'MK', text: 'MK' },
        // { value: 'AS', text: 'AS' },
        // { value: 'TK', text: 'TK' },
        // { value: 'VC', text: 'VC' },
        // { value: 'CSC', text: 'CSC' },
        // { value: 'DL', text: 'DL' },
        // { value: 'ST', text: 'ST' },
        // { value: 'TSC', text: 'TSC' },
        // { value: 'GST', text: 'GST' },
        // { value: 'OT', text: 'อื่นๆ' },
      // ],
    }
  },
  computed: {
    extractDay() {
      return new Date().getDate()
    },
    extractMonth() {
      const m = new Date().getMonth() + 1
      return m.toString().padStart(2, '0')
    },
    extractYear() {
      return new Date().getFullYear() + 543
    }
  },
  methods: {
    matchEmailPattern(str) {
      const regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regx.test(str)
    },
    formatDate(date, format = 'LL') {
      if (!date) { return '' }
      const IsoDateTo = moment(date).format(format)
      if (IsoDateTo === 'Invalid date') { return '' }
      return IsoDateTo
    },
    formatTime(date) {
      if (!date) { return '' }
      const IsoDateTo = moment(date).format('HH:mm:ss')
      if (IsoDateTo === 'Invalid date') { return '' }
      return IsoDateTo
    },
    detectImageType(file) {
      const re = /\.(gif|jpg|jpeg|tiff|png)$/i
      return re.test(file.name)
    },
    numberToThaiWords(num) {
      const number = [
        'ศูนย์',
        'หนึ่ง',
        'สอง',
        'สาม',
        'สี่',
        'ห้า',
        'หก',
        'เจ็ด',
        'แปด',
        'เก้า',
        'สิบ'
      ]
      const unit = [
        '',
        'สิบ',
        'ร้อย',
        'พัน',
        'หมื่น',
        'แสน',
        'ล้าน',
        'สิบ',
        'ร้อย',
        'พัน',
        'หมื่น',
        'แสน',
        'ล้าน'
      ]
      const total = num.toString().length
      let text = ''
      for (let index = 0; index < total; index++) {
        const t = num.toString().substring(index, index + 1)
        const convert = parseInt(t)
        if (convert !== 0) {
          if (index === total - 1 && convert === 1) {
            text += 'เอ็ด'
          } else if (index === total - 2 && convert === 2) {
            text += 'ยี่'
          } else if (index === total - 2 && convert === 1) {
            text += 'สิบ'
          } else {
            text = text + number[convert]
            text = text + unit[total - index - 1]
          }
        }
      }
      return text
    },
    stripCommas(number) {
      const search = number.toString().indexOf(',')
      let num = number
      if (search !== -1) {
        num = number.toString().replace(/,/g, '')
      }
      return num
    },
    formatAsThaiBaht(net, vat = true) {
      let number = net
      if (vat) {
        number = (net * 7) / 100 + net
      }
      return ThaiBahtText(number)

      // let number = net
      // if (vat) {
      //   // vat*100/107
      //   number = (net * 7) / 100 + net
      // }
      // // const convert = number.toLocaleString(undefined, {
      // //   minimumFractionDigits: 2,
      // //   maximumFractionDigits: 2
      // // })
      // // const num = number
      // const search = number.toString().indexOf('.')
      // if (search !== -1) {
      //   const split = number.toString().split('.')
      //   const primary = this.numberToThaiWords(split[0])
      //   const secondary = this.numberToThaiWords(split[1])
      //   return `${primary}บาท${secondary}สตางค์ถ้วน`
      // } else {
      //   const primary = this.numberToThaiWords([number])
      //   return `${primary}บาทถ้วน`
      // }
    },
    getVATAmount(nettotal, taxBase) {
      return (taxBase * nettotal) / (100 + taxBase)
    },
    includeVATInPrice(nettotal, taxBase) {
      if (!nettotal) return
      let vat = (taxBase * nettotal) / (100 + taxBase)
      let net = nettotal + vat
      return net
    },
    excludeVATFromPrice(nettotal, taxBase) {
      if (!nettotal) return
      let vat = (taxBase * nettotal) / (100 + taxBase)
      let net = nettotal - vat
      return net
    },
    onInitPositionSettings(item) {
      let position = '-'
      if (item) {
        const object = _.find(this.positions, _.flow(
          _.property('options'),
          _.partialRight(_.some, { value: item })
        ))
        if (object) {
          const result = _.find(object.options, { value: item })
          if (result) {
            position = result.text
          }
        }
      }
      return position
    },
    onInitGenderSettings(item) {
      let gender = '-'
      if (item) {
        const result = _.find(this.genders, { value: item })
        if (result) {
          gender = result.text
        }
      }
      return gender
    },
    onExceptionHandler(message = "เกิดข้อผิดพลาดกรุณาตรวจสอบ") {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-google"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
          `<span class="text-base text-left text-gray-500">${message}</span>` +
          '</div>' +
          '</div>',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: 'swal2-custom-rounded',
          closeButton: 'text-3xl swal2-custom-close',
          actions: 'justify-content-end',
          contant: 'd-flex flex-row justify-content-around px-2',
          title: 'd-none',
          confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
        },
        showCancelButton: false,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCloseButton: true,
      })
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
})
