import Vue from 'vue'
import Vuex from 'vuex'
import Branches from './branches'
import Documents from './documents'
import Machines from './machines'
import Suppliers from './suppliers'
import Customers from './customers'
import Products from './products'
import Users from './users'
import Auth from './auth'
import Brands from './brands'
import Stock from './stock'
import Setting from './setting'
import Invoice from './invoice'
import Bills from './bills'
import Deposit from './deposit'
import Receipts from './receipts'

Vue.use (Vuex)

export function createStore () {
  return new Vuex.Store ({
    strict: true,
    modules:{
      Invoice,
      Branches,
      Documents,
      Machines,
      Suppliers,
      Customers,
      Users,
      Products,
      Auth,
      Brands,
      Stock,
      Setting,
      Bills,
      Deposit,
      Receipts
    } 
  })
}
