<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="color-sangthai-dark d-block text-center text-sm-left d-sm-inline-block">Copyright © 2022 <a class="color-sangthai" href="https://www.sangthaimetalsheet.com/" target="_blank">Sangthaimetalsheet</a> All rights reserved. (V.2.0)</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center color-sangthai-dark"><i class="mdi mdi-heart text-danger"></i>Made by Convinapp co., ltd.</span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>