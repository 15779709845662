import Vue from 'vue'

const service = {
  getAvailable: function () {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/availablestock`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  }
}

export { service }
