import Vue from "vue"
import Router from "vue-router"
import layout from "../layout"
import auth from "../middleware/auth"

Vue.use(Router)

export function createRouter() {
  const router = new Router({
    linkExactActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    base: "/",
    routes: [
      // {
      //   path: "/apis",
      //   component: {
      //     render(c) {
      //       return c("router-view");
      //     },
      //   },
      //   children: [
      //     {
      //       path: "",
      //       name: "apis",
      //       component: () => import("@/pages/admin/apis"),
      //     },
      //   ],
      // },

      {
        path: "/documents",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "documents",
            component: () => import("@/pages/admin/documents"),
          },
        ],
      },

      {
        path: "/",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "login",
            component: () => import("@/pages/admin/auth/login"),
          },
        ],
      },
      {
        path: "/dashboard",
        component: layout,
        children: [
          {
            path: "",
            name: "dashboard",
            // component: () => import("@/pages/admin/dashboard"),
            component: () => import("@/pages/dashboard"),
          }
        ],
      },
      {
        path: "/dashboard/quotation",
        component: layout,
        children: [
          {
            path: "",
            name: "dashboard-quotation",
            component: () => import("@/pages/admin/dashboard/quotation"),
          }
        ],
      },
      {
        path: "/profile",
        meta: { middleware: [auth] },
        component: layout,
        children: [
          {
            path: "",
            name: "profile",
            component: () => import("@/pages/admin/auth/profile"),
          },
        ],
      },
      {
        path: "/permissions",
        component: layout,
        children: [
          {
            path: "",
            name: "permissions",
            component: () => import("@/pages/admin/authori/permissions"),
          }
        ],
      },
      {
        path: "/users",
        component: layout,
        children: [
          {
            path: "",
            name: "users",
            component: () => import("@/pages/admin/authori/users"),
          }
        ],
      },
      {
        path: "/purchase-order",
        component: layout,
        children: [
          {
            path: "",
            name: "purchasaeOrder",
            component: () =>
              import("@/pages/admin/orders/inbound/purchasae-order/widget-purchasae-list"),
          }
        ],
      },
      {
        path: "/return-customer",
        component: layout,
        children: [
          {
            path: "",
            name: "return-customer",
            component: () =>
            import("@/pages/admin/orders/inbound/return-customer/widget-return-list"),
          }
        ],
      },
      {
        path: "/other-order",
        component: layout,
        children: [
          {
            path: "",
            name: "other-order",
            component: () => import("@/pages/admin/orders/inbound/other-order/widget-otherorder-list"),
          }
        ],
      },
      {
        path: "/sale-order",
        component: layout,
        children: [
          {
            path: "",
            name: "sale-order",
            component: () => import("@/pages/admin/orders/outbound/sale-order/widget-saleorder-list"),
          }
        ],
      },
      {
        path: "/return-seller",
        component: layout,
        children: [
          {
            path: "",
            name: "return-seller",
            component: () =>
              import("@/pages/admin/orders/outbound/return-seller/widget-return-seller-list"),
          }
        ],
      },
      {
        path: "/requisition",
        component: layout,
        children: [
          {
            path: "",
            name: "requisition",
            component: () => import("@/pages/admin/orders/outbound/requisition/widget-requisition-list"),
          }
        ],
      },
      {
        path: "/weight-list",
        component: layout,
        children: [
          {
            path: "",
            name: "weight-list",
            component: () => import("@/pages/admin/orders/weight-list"),
          }
        ],
      },
      {
        path: "/branch-stock",
        component: layout,
        children: [
          {
            path: "",
            name: "branch-stock",
            component: () => import("@/pages/admin/orders/branch-stock"),
          }
        ],
      },
      {
        path: "/branch-stock/miscellaneous",
        component: layout,
        children: [
          {
            path: "",
            name: "branch-stock-miscellaneous",
            component: () => import("@/pages/admin/orders/branch-stock/miscellaneous"),
          }
        ],
      },
      {
        path: "/request",
        component: layout,
        children: [
          {
            path: "",
            name: "request",
            component: () => import("@/pages/admin/orders/request-document"),
          }
        ],
      },
      {
        path: "/request/add",
        component: layout,
        children: [
          {
            path: "",
            name: "create-request",
            component: () => import("@/pages/admin/orders/request-document/add"),
          }
        ],
      },
      {
        path: "/request/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "request-order",
            component: () => import("@/pages/admin/orders/request-document/detail"),
          }
        ],
      },
      {
        path: "/createPOfromRO/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "create-po-from-ro",
            component: () => import("@/pages/admin/orders/order-centric/widget-document/document-create/document-create-purchase-order"),
          }
        ],
      },
      {
        path: "/order-centric",
        component: layout,
        children: [
          {
            path: "",
            name: "order-centric",
            component: () => import("@/pages/admin/orders/order-centric/widget-purchasae-list"),
          },
          {
            path: "added",
            name: "order-centric-create",
            component: () => import("@/pages/admin/orders/order-centric/widget-document/document-create/document-create-purchase-order"),
          },
          {
            path: "greet-customer",
            name: "order-centric-greet-customer",
            component: () => import("@/pages/admin/orders/order-centric/widget-document/document-create/document-create-customer"),
          }
        ],
      },
      {
        path: "/order-centric/po/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "order-centric-purchase-order",
            component: () => import("@/pages/admin/orders/order-centric/widget-purchase-order"),
          }
        ],
      },
      {
        path: "/order-centric/po/edit/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "get-order-centric-purchase-order",
            component: () => import("@/pages/admin/orders/order-centric/widget-edit-purchase-order"),
          }
        ],
      },
      {
        path: "/order-centric/customer/edit/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "get-order-centric-purchase-order-customer",
            component: () => import("@/pages/admin/orders/order-centric/widget-document/document-create/document-create-customer"),
          }
        ],
      },
      {
        path: "/pickup/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "checker-pickup",
            component: () => import("@/pages/admin/checker"),
          }
        ],
      },
      {
        path: "/order-centric/receive/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "checker-receive",
            component: () => import("@/pages/admin/checker/receive"),
          }
        ],
      },
      {
        path: "/order-centric/inv/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "order-centric-invoice",
            component: () => import("@/pages/admin/orders/order-centric/widget-invoice-detail"),
          }
        ],
      },
      {
        path: "/stock/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "stock",
            component: () => import("@/pages/admin/orders/stock"),
          }
        ],
      },
      {
        path: "/sales",
        component: layout,
        children: [
          {
            path: "",
            name: "sales",
            component: () => import("@/pages/admin/orders/sales"),
          }
        ],
      },
      {
        path: "/production",
        component: layout,
        children: [
          {
            path: "",
            name: "production",
            component: () => import("@/pages/admin/orders/production/widget-production-list"),
          },
          {
            path: "create",
            name: "create-production",
            component: () => import("@/pages/admin/orders/production/widget-create"),
          }
        ],
      },
      {
        path: "/stockin",
        component: layout,
        children: [
          {
            path: "",
            name: "stockin",
            component: () => import("@/pages/admin/inventory/stockin"),
          },
        ],
      },
      {
        path: "/stockout",
        component: layout,
        children: [
          {
            path: "",
            name: "stockout",
            component: () => import("@/pages/admin/inventory/stockout/widget-stock-list"),
          }
        ]
      },
      {
        path: "/batch",
        component: layout,
        children: [
          {
            path: "",
            name: "batch",
            component: () => import("@/pages/admin/inventory/batch"),
          },
        ],
      },
      {
        path: "/transpose",
        component: layout,
        children: [
          {
            path: "",
            name: "transpose",
            component: () => import("@/pages/admin/inventory/transpose"),
          },
        ],
      },
      {
        path: "/adjustment",
        component: layout,
        children: [
          {
            path: "",
            name: "adjustment",
            component: () => import("@/pages/admin/inventory/adjustment/widget-adj-list"),
          },
          {
            path: "added",
            name: "added-adj",
            component: () => import("@/pages/admin/inventory/adjustment/widget-adj-create"),
          }
        ]
      },
      {
        path: "/billing",
        component: layout,
        children: [
          {
            path: "",
            name: "billing",
            component: () => import("@/pages/admin/receivable/billing/widget-billing-list"),
          },
          {
            path: "create",
            name: "billing-create",
            component: () => import("@/pages/admin/receivable/billing/widget-billing-create"),
          },
          {
            path: "create/:id",
            name: "edit",
            component: () => import("@/pages/admin/receivable/billing/widget-billing-create"),
          },
          {
            path: "goods_note",
            name: "goods_note",
            component: () => import("@/pages/admin/receivable/billing/widget-billing-delivery-note"),
          },
          {
            path: "goods_production",
            name: "goods_production",
            component: () => import("@/pages/admin/receivable/billing/widget-billing-production"),
          }
        ],
      },
      {
        path: "/delivery",
        component: layout,
        children: [
          {
            path: "",
            name: "delivery",
            component: () => import("@/pages/admin/receivable/delivery/widget-delivery-list"),
          },
        ]
      },
      {
        path: "/payment",
        component: layout,
        children: [
          {
            path: "",
            name: "payment",
            component: () => import("@/pages/admin/receivable/payment/widget-payment-list"),
          }
        ],
      },
      {
        path: "/balance",
        component: layout,
        children: [
          {
            path: "",
            name: "outstand_debt",
            component: () => import("@/pages/admin/receivable/balance"),
          },
        ],
      },
      {
        path: "/deposit",
        component: layout,
        children: [
          {
            path: "",
            name: "deposit",
            component: () => import("@/pages/admin/receivable/deposit/widget-deposit-list"),
          },
          {
            path: "added",
            name: "deposit-added",
            component: () => import("@/pages/admin/receivable/deposit/widget-deposit-create"),
          },
          {
            path: "added/:id",
            name: "deposit-edit",
            component: () => import("@/pages/admin/receivable/deposit/widget-deposit-create"),
          },
          {
            path: "info/:id",
            name: "deposit-info",
            component: () => import("@/pages/admin/receivable/deposit/widget-deposit-info"),
          }
        ],
      },
      {
        path: "/invoice",
        component: layout,
        children: [
          {
            path: "",
            name: "invoice",
            component: () => import("@/pages/admin/receivable/invoice/widget-invoice-list"),
          },
          {
            path: "added",
            name: "invoice-added",
            component: () => import("@/pages/admin/receivable/invoice/widget-invoice-create"),
          },
          {
            path: "edit/:id",
            name: "invoice-update",
            component: () => import("@/pages/admin/receivable/invoice/widget-invoice-create"),
          }
        ],
      },
      {
        path: "/invoice/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "getinvoice",
            component: () => import("@/pages/admin/receivable/invoice/widget-invoice-detail"),
          }
        ],
      },
      {
        path: "/quotation",
        component: layout,
        children: [
          {
            path: "",
            name: "quotation",
            component: () => import("@/pages/admin/receivable/quotation"),
          }
        ],
      },
      {
        path: "/quotation/create",
        component: layout,
        children: [
          {
            path: "",
            name: "create-quotation",
            component: () => import("@/pages/admin/receivable/quotation/widgets/widget-quotation-create"),
          }
        ],
      },
      {
        path: "/quotation/edit/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "edit-quotation",
            component: () => import("@/pages/admin/receivable/quotation/widgets/widget-quotation-create"),
          }
        ],
      },
      {
        path: "/quotation/revise/:id",
        component: layout,
        children: [
          {
            path: "",
            name: "revise-quotation",
            component: () => import("@/pages/admin/receivable/quotation/widgets/widget-quotation-create"),
          }
        ],
      },
      {
        path: "/quotation/accounts",
        component: layout,
        children: [
          {
            path: "",
            name: "quotation-accounts",
            component: () => import("@/pages/admin/receivable/quotation/accounts"),
          }
        ],
      },
      {
        path: "/receipt",
        component: layout,
        children: [
          {
            path: "",
            name: "receipt",
            component: () => import("@/pages/admin/receivable/receipt/widget-receipt-list"),
          }
        ],
      },
      {
        path: "/tax-invoice",
        component: layout,
        children: [
          {
            path: "",
            name: "tax-invoice",
            component: () => import("@/pages/admin/receivable/tax-invoice/widget-tax-invoice-list"),
          }
        ]
      },
      {
        path: "/report",
        component: layout,
        children: [
          {
            path: "balance",
            name: "balance",
            component: () => import("@/pages/admin/reports/balance"),
          },
          {
            path: "inbound",
            name: "inbound",
            component: () => import("@/pages/admin/reports/inbound"),
          },
          {
            path: "outbound",
            name: "outbound",
            component: () => import("@/pages/admin/reports/outbound"),
          },
          {
            path: "manufacture",
            name: "manufacture",
            component: () => import("@/pages/admin/reports/production"),
          },
          {
            path: "ar-aging",
            name: "ar-aging",
            component: () => import("@/pages/admin/reports/ar_aging"),
          },
          {
            path: "movement",
            name: "movement",
            component: () => import("@/pages/admin/reports/movement"),
          },
          {
            path: "ar-sale",
            name: "ar-sale",
            component: () => import("@/pages/admin/reports/ar_sale"),
          },
          {
            path: "ar-payment",
            name: "ar-payment",
            component: () => import("@/pages/admin/reports/ar_payment"),
          },
          {
            path: "sale",
            name: "sale",
            component: () => import("@/pages/admin/reports/sale"),
          },
          {
            path: "spending",
            name: "spending",
            component: () => import("@/pages/admin/reports/spending"),
          },
          {
            path: "revenue",
            name: "revenue",
            component: () => import("@/pages/admin/reports/revenue"),
          },
          {
            path: "reservation",
            name: "reservation",
            component: () => import("@/pages/admin/reports/reservation"),
          },
          {
            path: "repay",
            name: "repay",
            component: () => import("@/pages/admin/reports/payment"),
          },
          {
            path: "credit-note",
            name: "credit_note",
            component: () => import("@/pages/admin/reports/credit_note"),
          },
          {
            path: "withholding-tax",
            name: "withholding_tax",
            component: () => import("@/pages/admin/reports/withholding_tax"),
          },
          {
            path: "profitability",
            name: "profitability",
            component: () => import("@/pages/admin/reports/profitability"),
          },
        ],
      },
      {
        path: "/miscellaneous",
        component: layout,
        children: [
          {
            path: "",
            name: "miscellaneous",
            component: () => import("@/pages/admin/masters/products"),
          }
        ]
      },
      {
        path: "/masters",
        component: layout,
        children: [
          {
            path: "branch",
            name: "branch",
            component: () => import("@/pages/admin/masters/branch"),
          },
          {
            path: "brands",
            name: "brands",
            component: () => import("@/pages/admin/masters/brands"),
          },
          {
            path: "customer",
            name: "customer",
            component: () => import("@/pages/admin/masters/customer"),
          },
          {
            path: "customer/types",
            name: "customer-types",
            component: () => import("@/pages/admin/masters/customer-type"),
          },
          {
            path: "supplier",
            name: "supplier",
            component: () => import("@/pages/admin/masters/supplier"),
          },
          {
            path: "location",
            name: "location",
            component: () => import("@/pages/admin/masters/location"),
          },
          {
            path: "product-types",
            name: "product-types",
            component: () => import("@/pages/admin/masters/product-types"),
          },
          {
            path: "skus",
            name: "skus",
            component: () => import("@/pages/admin/masters/skus"),
          },
          {
            path: "sizes",
            name: "sizes",
            component: () => import("@/pages/admin/masters/sizes"),
          },
          {
            path: "colors",
            name: "colors",
            component: () => import("@/pages/admin/masters/colors"),
          },
        ],
      },
      {
        path: "/settings",
        component: layout,
        children: [
          {
            path: "company",
            name: "company",
            component: () => import("@/pages/admin/settings/company"),
          },
        ],
      },
      {
        path: "/check",
        component: layout,
        children: [
          {
            path: "image",
            name: "check-image",
            component: () => import("@/pages/admin/settings/check"),
          },
        ],
      },
      {
        path: "*",
        redirect: "/error-404",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "error-404",
            component: () => import("@/pages/admin/auth/error/404.vue"),
          },
        ],
      },
      {
        path: '/icons',
        component: layout,
        children: [
          {
            path: 'flag-icons',
            name: 'flag-icons',
            component: () => import('@/pages/icons/flag-icons')
          },
          {
            path: 'fontawesome',
            name: 'fontawesome',
            component: () => import('@/pages/icons/fontawesome')
          },
          {
            path: 'mdi-icons',
            name: 'mdi-icons',
            component: () => import('@/pages/icons/mdi-icons')
          },
          {
            path: 'simpleline',
            name: 'simpleline',
            component: () => import('@/pages/icons/simple-line')
          },
          {
            path: 'themify-icons',
            name: 'themify-icons',
            component: () => import('@/pages/icons/themify')
          }
        ]
      },
    ],
  })
  return router
}

