import Vue from 'vue'

const service = {
  get: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/setting/company`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  update: function (data) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/setting/${data._id}`, data.formData, settings)
      resolve (result)
    })
  }
}

export { service }
