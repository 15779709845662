import Header from "./partials/Header"
import Sidebar from "./partials/Sidebar"
import Footer from "./partials/Footer"

export default {
  name: "layout",
  components: {
    Header,
    Sidebar,
    Footer
  }
}
