import Vue from 'vue'
const service = {
  getAll: function () {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/branches`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  },
  get: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/branch/${params._id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  search: function (params) {
    return new Promise (async function (resolve, reject) {
      const url = params?.url?? `${Vue.prototype.$host}/api/v1/branch?keyword=${params.keyword}`
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: url,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  delete: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'delete',
        url: `${Vue.prototype.$host}/api/v1/branch/${params._id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  create: function (formData) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/branch`, formData, settings)
      resolve (result)
    })
  },
  update: function (data) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/branch/${data._id}`, data.formData, settings)
      resolve (result)
    })
  }
}

export { service }
